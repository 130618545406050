.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-fade {
  text-align: center;
  animation: fadeIn 2s;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
/*  Portal Style  */

.listEC2Running {
  background: green;
  border-radius: 8px;
  color: white;
  font-size: 1em;
}
.running {
  background: green;
  border-radius: 8px;
  color: white;
  font-size: 0.5em;
}
.listEC2Stopped {
  background: red;
  border-radius: 8px;
  color: white;
  font-size: 1em;
}
.stopped {
  background: red;
  border-radius: 8px;
  color: white;
  font-size: 0.5em;
}

/* Scroll bar Spec */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #9ad9cb;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #34e0bb;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00ffc8;
}


